const EDIT_TYPE_CORRECT = "COR";
const EDIT_TYPE_REVERSE = "REV";
const EDIT_TYPE_COLORS = {
  [EDIT_TYPE_CORRECT]: "cyan",
  [EDIT_TYPE_REVERSE]: "orange"
};
const EDIT_TYPE_TEXTS = {
  [EDIT_TYPE_CORRECT]: "Correct",
  [EDIT_TYPE_REVERSE]: "Reverse"
};
var createSubscriptions = () => {
  return {
    onFetchListSuccess(state) {
      const { entities, list } = state;
      const editedIntakes = list.map((id) => entities[id]);
      editedIntakes.forEach((intake, index, array) => {
        if (intake.editType == EDIT_TYPE_CORRECT)
          return;
        const { id, intakeNumber } = intake;
        const corrected = array.findIndex((item) => item.intakeNumber == intakeNumber && item.editType == EDIT_TYPE_CORRECT) > -1;
        if (corrected) {
          entities[id].corrected = true;
        }
      });
      state.entities = entities;
    }
  };
};
export { EDIT_TYPE_REVERSE as E, EDIT_TYPE_COLORS as a, EDIT_TYPE_TEXTS as b, EDIT_TYPE_CORRECT as c, createSubscriptions as d };
