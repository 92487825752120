import { E as EDIT_TYPE_REVERSE, a as EDIT_TYPE_COLORS, b as EDIT_TYPE_TEXTS, c as EDIT_TYPE_CORRECT, d as createSubscriptions } from "./reverse.correct.js";
import { MAINTAIN_PERMISSION, GROWERS_REVERSECORRECT } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    staticClass: "list-reverse-correct-intakes",
    attrs: {
      "actions-width": 0,
      "create-button-text": "Reverse Intake",
      "has-active": false,
      "ph-search-by": "Search by Intake Code, Reason",
      "search-by": "IntakeNumber,NewIntakeNumber,Reason",
      "title": "Reverse/Correct"
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("div", {
          staticClass: "edit-type-filter mr-2"
        }, [_c("select-filter", {
          attrs: {
            "source": "value",
            "source-label": "text",
            "data-source": _vm.filterOptions,
            "default-active-first-option": ""
          },
          on: {
            "change": _vm.onTypeFilterChange
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_c("div", [_vm.hasMaintainPermission && record.editType == _vm.EDIT_TYPE_REVERSE && !record.corrected ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Correct")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "check-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()], 1)];
      }
    }])
  }, [_c("tag-field", {
    key: "EditType",
    attrs: {
      "color": _vm.getEditTypeColor,
      "data-index": "editType",
      "title": "Edit Type",
      "parse": _vm.parseEditType,
      "sorter": true,
      "width": 96
    }
  }), _c("text-field", {
    key: "IntakeNumber",
    attrs: {
      "data-index": "intakeNumber",
      "sorter": true,
      "title": "Old Intake Code"
    }
  }), _c("text-field", {
    key: "NewIntakeNumber",
    attrs: {
      "data-index": "newIntakeNumber",
      "sorter": true,
      "title": "New Intake Code"
    }
  }), _c("text-field", {
    key: "OriginalStatus",
    attrs: {
      "data-index": "originalStatus",
      "title": "Original Status"
    }
  }), _c("text-field", {
    key: "Reason",
    attrs: {
      "data-index": "reason",
      "title": "Reason"
    }
  }), _c("text-field", {
    key: "EditDate",
    attrs: {
      "data-index": "editDate",
      "parse": _vm.parseEditDate,
      "sorter": true,
      "title": "Edit Date"
    }
  }), _c("text-field", {
    key: "UserID",
    attrs: {
      "data-index": "userID",
      "sorter": true,
      "title": "User ID"
    }
  })], 1);
};
var staticRenderFns$1 = [];
var ListReverseCorrectIntake_vue_vue_type_style_index_0_scoped_true_lang = "";
const FIELD_EDIT_TYPE = "EditType";
const __vue2_script$1 = {
  name: "ReverseCorrectIntake",
  inject: ["crud", "can"],
  data() {
    const filterOptions = [
      { value: "all", text: "All", color: "black" },
      {
        value: EDIT_TYPE_CORRECT,
        text: EDIT_TYPE_TEXTS[EDIT_TYPE_CORRECT],
        color: EDIT_TYPE_COLORS[EDIT_TYPE_CORRECT]
      },
      {
        value: EDIT_TYPE_REVERSE,
        text: EDIT_TYPE_TEXTS[EDIT_TYPE_REVERSE],
        color: EDIT_TYPE_COLORS[EDIT_TYPE_REVERSE]
      }
    ];
    return {
      EDIT_TYPE_REVERSE,
      filterOptions
    };
  },
  computed: {
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  methods: {
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    },
    getEditTypeColor(value) {
      return EDIT_TYPE_COLORS[value];
    },
    parseEditDate(value) {
      return this.$moment(value).format("DD/MM/YY hh:mm A");
    },
    parseEditType(value) {
      return EDIT_TYPE_TEXTS[value];
    },
    onTypeFilterChange(status) {
      if (status.value == "all") {
        this.crud.deleteFilter(FIELD_EDIT_TYPE);
        this.fetchList();
        return;
      }
      this.crud.setFilter(FIELD_EDIT_TYPE, status);
      this.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "392ff3df", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListReverseCorrectIntake = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.intake-edits",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/reverse-correct-intakes/reverse",
      "edit-route": "/watties-grower/reverse-correct-intakes/:id/correct",
      "subscriptions": _vm.subscriptions,
      "page": _vm.page
    }
  }, [_c("list-reverse-correct-intake")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ListReverseCorrectIntake
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      subscriptions: createSubscriptions(),
      page: GROWERS_REVERSECORRECT,
      menuItems: [
        {
          title: "Intake Processing",
          path: ""
        },
        {
          title: "Reverse/Correct",
          path: "/watties-grower/reverse-correct-intakes"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
